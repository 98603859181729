$(document).on('turbolinks:load', function() {
  $('#schedule-items-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#schedule-items-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "name"},
      {"data": "parent_schedule_item"}, 
      {"data": "client"}, 
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 3 }, { "width": "5%", "targets": 3 },{ "className": 'dt-center', "targets": 3 }]
  });
});