$(document).on('turbolinks:load', function() {
  $('#clients-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#clients-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "client_name"},
      {"data": "primary_contact"},
      {"data": "phone"}, 
      {"data": "fax"}, 
      {"data": "mobile"},
      {"data": "email"}, 
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 6 }, { "width": "5%", "targets": 6 },{ "className": 'dt-center', "targets": 6 }]
  });
});