$(document).on('turbolinks:load', function() {
  $('#contacts-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#contacts-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "client"},
      {"data": "full_name"},
      {"data": "phone"}, 
      {"data": "mobile"}, 
      {"data": "email"}, 
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 5 }, { "width": "5%", "targets": 5 },{ "className": 'dt-center', "targets": 5 }]
  });
});