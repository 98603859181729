$(document).on('turbolinks:load', function() {
  $('#sites-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#sites-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "site_name"},
      {"data": "client"},
      {"data": "address_street"},
      {"data": "address_suburb"},
      {"data": "address_postcode"},
      {"data": "primary_contact_name"},
      {"data": "primary_contact_phone"}, 
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 7 }, { "width": "15%", "targets": 7 },{ "className": 'dt-center', "targets": 7 }]
  });
});