$(document).on('turbolinks:load', function() {
  $('#daily-hire-employee-hours-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#daily-hire-employee-hours-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "employee"},
      {"data": "start_depot"},
      {"data": "arrive_site"},
      {"data": "leave_site"},
      {"data": "leave_disposal_site"},
      {"data": "job_finish"},
      {"data": "ordinary_hours"},
      {"data": "down_time"},
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 8 }, { "width": "5%", "targets": 8 },{ "className": 'dt-center', "targets": 8 }]
  });
});

