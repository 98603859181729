// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('perfect-scrollbar')
require('datatables.net-dt')
require("theme/starlight")
require("jquery-validation")

require("trix")
require("@rails/actiontext")

require("departments.js")
require("vehicles.js")
require("clients.js")
require("sites.js")
require("quotes.js")
require("schedule_items.js")
require("quote_items.js")
require("jobs.js")
require("job_items.js")
require("employees.js")
require("scheduled_days.js")
require("employee_schedules.js")
require("contacts.js")
require("daily_hire_sockets.js")
require("daily_hire_employee_hours.js")
require("tasks.js")
require("users.js")
require("wip_report.js")
require("wip_as_at_report.js")

require('dropzone')

import "@fortawesome/fontawesome-free/js/all";
//import "@fortawesome/fontawesome-free/css/all";

import Dropzone from 'dropzone'

import $ from 'jquery';
global.$ = jQuery;
require('jquery-ui');
import AutoNumeric from 'autonumeric';
global.AutoNumeric = AutoNumeric;


require("@nathanvda/cocoon")

import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
//Dropzone.autoDiscover = false;

$(document).on('turbolinks:load', function() {
$('#global-modal-extra').on('hidden.bs.modal', function () {
  $("#global-modal").css("opacity", "");
  $("body").addClass("modal-open");
})
	

	$('.select2').select2({
    	theme: "bootstrap"
  	});

	$(document).on("change", ".qty", function(){
		var qty = parseFloat($(this).val());
		var price = parseFloat($(this).parent().siblings('.price-outer').find('.price').val().replace(/,/g, "").replace('$ ', ""));

		var tot_price = qty * price;
		if(Number.isNaN(tot_price) == false){
			AutoNumeric.set($(this).parent().siblings('.total-outer').find('.total')[0], tot_price);
		}else{
			$(this).parent().siblings('.total-outer').find('.total').val("");
		}
		
		var total = 0
		$(".total").each(function() {
		  total += parseFloat($(this).val().replace(/,/g, "").replace('$ ', ""));
		});
		$("#grand-total").html("$" + total.toFixed(2));

	});
	$(document).on("change", ".price", function(){
		var qty = parseInt($(this).parent().siblings('.qty-outer').find('.qty').val());
		var price = parseFloat($(this).val().replace(/,/g, "").replace('$ ', ""));

		var tot_price = qty * price;
		if(Number.isNaN(tot_price) == false){
			AutoNumeric.set($(this).parent().siblings('.total-outer').find('.total')[0], tot_price);
		}else{
			$(this).parent().siblings('.total-outer').find('.total').val("");
		}
		var total = 0
		$(".total").each(function() {
		  total += parseFloat($(this).val().replace(/,/g, "").replace('$ ', ""));
		});
		$("#grand-total").html("$" + total.toFixed(2));
	});

	$(document).on("change", ".schedule_item", function(){
		if($(this).val() != ""){
			$(this).parent().siblings('.qty-outer').find('.qty').val("1");
			var el = $(this);
		$.get("/get-scheduled-item-price?id="+$(this).val()+"",  // url
		      function (data, textStatus, jqXHR) {  // success callback
		      	var price = parseInt(data["price"]);
		      	var total = 1 * price;
		      	AutoNumeric.set(el.parent().siblings('.price-outer').find('.price')[0], price);
		      	AutoNumeric.set(el.parent().siblings('.total-outer').find('.total')[0], total);
		      });
	}



});

	new AutoNumeric.multiple('.currency-field',['dollar', { currencySymbol: '$ ' }, {currencySymbolPlacement: 'p'}]);
	$('.modal').on('shown.bs.modal', function() {
		new AutoNumeric.multiple('.currency-field',['dollar', { currencySymbol: '$ ' }, {currencySymbolPlacement: 'p'}]);
		$(this).find('form').validate();
		$(this).find('[autofocus]:first').focus();
		$('.modal form').submit(function() {
			$('.modal form button[type=submit]').prop('disabled', true);
			$('.modal form button[type=submit]').prepend('<i class="fa fa-spinner fa-pulse fa-spin"></i> ');
		});

		$(document).on("change", ".qty", function(){
			var qty = parseInt($(this).val());
			var price = parseFloat($(this).parent().siblings('.price-outer').find('.price').val().replace(/,/g, "").replace('$ ', ""));

			var tot_price = qty * price;
			if(Number.isNaN(tot_price) == false){
				AutoNumeric.set($(this).parent().siblings('.total-outer').find('.total')[0], tot_price);
			}else{
				$(this).parent().siblings('.total-outer').find('.total').val("");
			}

		});
		$(document).on("change", ".price", function(){
			var qty = parseInt($(this).parent().siblings('.qty-outer').find('.qty').val());
			var price = parseFloat($(this).val().replace(/,/g, "").replace('$ ', ""));

			var tot_price = qty * price;
			if(Number.isNaN(tot_price) == false){
				AutoNumeric.set($(this).parent().siblings('.total-outer').find('.total')[0], tot_price);
			}else{
				$(this).parent().siblings('.total-outer').find('.total').val("");
			}

		});

	}); 

	$("#upload-dropzone").dropzone();
	function show_attachment_item(json) {
		var html = json 
		$('#attachments').append(html);      
	} 
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
