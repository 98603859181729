$(document).on('turbolinks:load', function() {
  $('#users-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#users-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "name"},
      {"data": "email"},
      {"data": "department"}, 
      {"data": "user_status"}, 
      {"data": "access_role"},
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 5 }, { "width": "5%", "targets": 5 },{ "className": 'dt-center', "targets": 5 }]
  });
});