$(document).on('turbolinks:load', function() {
  $('#quotes-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#quotes-table').data('source')
    },
    "order": [ [ 0, 'desc'] ],
    "pagingType": "full_numbers",
    "columns": [
      {"data": "quote_number"},
      {"data": "title"},
      {"data": "client"},
      {"data": "site" },
      {"data": "contact" },
      {"data": "contact_email" },
      {"data": "contact_phone" },
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 7 }, { "width": "5%", "targets": 7 },{ "className": 'dt-center', "targets": 7 }]
  });
});