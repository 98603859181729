$(document).on('turbolinks:load', function() {
    $('#wip-as-at-report').dataTable({
      "processing": true,
      "serverSide": true,
      "ajax": {
        "url": $('#wip-report').data('source')
      },
      "order": [ [ 1, 'desc'] ],
      "pagingType": "full_numbers",
      "columns": [
        {"data": "due_date" },
        {"data": "job_number"},
        {"data": "title"},
        {"data": "job_status"},
        {"data": "completed_date"},
        {"data": "client"},
        {"data": "job_items_total_cost"},
        {"data": "actuals_total_cost"}, 
        {"data": "percentage_complete" }
      ],
      columnDefs: [{ "orderable": false, "targets": [6,7,8]}]
    });
  });