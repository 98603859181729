$(document).on('turbolinks:load', function() {
  $('#quote-items-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#quote-items-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "quote"},
      {"data": "schedule_item"},
      {"data": "qty"},
      {"data": "price"},
      {"data": "total"}, 
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 5 }, { "width": "5%", "targets": 5 },{ "className": 'dt-center', "targets": 5 }]
  });
});