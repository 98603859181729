$(document).on('turbolinks:load', function() {
  $('#daily-hire-dockets-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#daily-hire-dockets-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "job"},
      {"data": "date"},
      {"data": "order_number"},
      {"data": "project_number"},
      {"data": "unit_number"},
      {"data": "vehicle_registration"},
      {"data": "other"},
      {"data": "vehicle_cleaned"},
      {"data": "fill_water"},
      {"data": "refuelled"},
      {"data": "ops"},
    ], 
    columnDefs: [{ "orderable": false, "targets": 10 }, { "width": "5%", "targets": 10 },{ "className": 'dt-center', "targets": 10 }]
  });
});