$(document).on('turbolinks:load', function() {
  $('#jobs-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#jobs-table').data('source')
    },
    "order": [ [ 0, 'desc'] ],
    "pagingType": "full_numbers",
    "columns": [
      {"data": "job_number"},
      {"data": "title"},
      {"data": "client"},
      {"data": "department"},
      {"data": "referring_quote"}, 
      {"data": "site" },
      {"data": "contact" },
      {"data": "contact_email" },
      {"data": "contact_phone" },
      {"data": "status" },
      {"data": "ops"}
    ], 
    columnDefs: [{ "orderable": false, "targets": 10}, { "width": "4%", "targets": 10 },{ "className": 'dt-center', "targets": 10}]
  });
});